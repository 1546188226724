<template>
  <div id="app">
    <Loading v-if="state.loading"/>
    <Navbar v-if="!state.token" />
    <DashNavbar style="position:fixed" v-if="state.token && !$route.path.includes('dashboard') && !$route.path.includes('advancetrade')" />
      <router-view/>
    <Footer v-if="$route.name =='AboutUs' || $route.name =='Faq' || $route.name =='Fee' || $route.name =='Market' || $route.name =='PrivacyAndPolicy' || $route.name =='HomePage'" />
  </div>
</template>

<script>
import Navbar from './components/Public/Navbar.vue';
import Footer from './components/Public/Footer.vue';
import Loading from './components/Public/Loading.vue';
import DashNavbar from './components/Panel/DashNavbar.vue';

export default {
  name: 'App',
  components:{
    Navbar,
    Footer,
    Loading,
    DashNavbar
  },
  mounted() {
    
  },
}
</script>

<style>

</style>
