<template>
    <div class="fpass-container">
        <div class="fpassword">
            <h2> بازیابی رمز عبور </h2>
            <form @submit.prevent="submit" class="input">
                <div class="input-container">
                            <span class="input-span"> ایمیل </span>
                            <label class="no-just" for="email">
                                <img src="../../assets/icons/darkEmail.svg" alt="">
                                <input v-model="otp.email" type="text">
                            </label>
                </div>
                <!-- <img src="../../assets/recaptcha.svg" alt=""> -->
                <button :disabled="!otp.email || !$validEmail(otp.email)" class="submit"> ارسال کد بازیابی </button>
            </form>
        </div>
    </div>
</template>
<script>
const goto = {
        EMAIL: '/otpforgetpass/EMAIL',
        SMS: '/otpforgetpass/SMS',
        GOOGLE_AUTHENTICATOR: '/otpforgetpass/GOOGLE_AUTHENTICATOR',
}
export default {
    name:'ForgetPass',
    data() {
        return {
            otp:{
                email:''
            },
        }
    },
    methods:{
        async submit(){
            if (this.$validEmail(this.otp.email)) {
                    this.state.loading = true
                    const res = await this.$axios.post('/users/forget-password?email=' + this.otp.email)
                    this.$cookies.set('username', this.otp.email)
                    this.state.userInfo.email = this.otp.email
                    if (res.baseDTO) {
                        this.state.userInfo.mobilenumber = res.baseDTO.starTwoStepAuthentication || '';
                        this.state.userInfo.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType;
                    } else {
                        this.state.userInfo.towStepAuthenticationType = 'EMAIL'
                    }
                    await this.$router.push(goto[res.baseDTO.towStepAuthenticationType])
                }
        }
    },
}
</script>

<style lang="scss" scoped>
.fpass-container{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    align-self: flex-start;
    margin-top: 140px;
    justify-self: center;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.input{
    z-index: 5;
    display: flex;
    flex-direction: column;
    padding: 24px;
    row-gap: 32px;
    background: white;
    border-radius: 12px;

}
.fpassword{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: 30%;
}
h2{
    z-index: 5;
    font-family: 'sepahbod';
    color: var(--purple-title);

}
@media only screen and(max-width:1300px){
    .fpassword{
        width: 50%;
    }
}
@media only screen and(max-width:1100px){
    .fpassword{
        width: 70%;
    }
}
@media only screen and(max-width:900px){
    .fpassword{
        width: 85%;
    }
}
@media only screen and(max-width:600px){
    .fpassword{
        width: 100%;
    }
}
</style>