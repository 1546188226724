<template>
    <div class="otp">
        <h3> تایید ایمیل </h3>
        <form @submit.prevent="handleSubmit(otp.code)" class="ci-container">
            <div class="title">
                <span>
                    کد ارسال شده به
                    {{$athenType[$route.params.authType]}}
                    {{ $route.params.authType == 'EMAIL' ?  otp.email : state.userInfo.mobilenumber}}
                    را وارد کنید
                </span>
                <a @click.prevent="$router.push('/login')" href="/login" class="back"> استفاده از ایمیل دیگر </a>
            </div>
            <CodeInput
                :loading="false" 
                class="verif-code" 
                @complete="handleSubmit"
            />
            <span @click="resendBtn" class="counter"> {{counter ? counter : 'ارسال مجدد کد'}} </span>
            <button :disabled="otp.code.length < 5" class="submit"> تایید </button>
        </form>
    </div>
</template>


<script>
import CodeInput from "vue-verification-code-input";
import {reset,paste} from "@/library/reuseableFunction";
export default {
    name:'Otp',
    components:{
        CodeInput,
    },
    data(){
        return{
            counter:90,
            timeOut:null,
            otp:{
                    code: '',
                    email: this.state.userInfo.email || this.$cookies.get('username')
            }
        }
    },
    computed:{

    },
    methods:{
        async resendBtn() {
                this.state.loading = true
                this.reset()
                await this.$axios.post(`/users/resend-otp-code?email=${this.$cookies.get('username')}`)
                this.$error('کد جدید به ' + this.$athenType[this.$route.params.authType] + ' شما ارسال شد.', '', 'success')
                this.$cookies.set('username', this.state.userInfo.email)
                if (this.countdown === 0) {
                    this.countdown = 90
                    this.countDownTimer()
                }

        },
        countDownTimer() {
                if (this.counter > 0) {
                    this.timeOut = setTimeout(() => {
                        this.counter--
                        this.countDownTimer()
                    }, 1000)
                } else {
                    this.$error('زمان شما به اتمام رسید', '')
                }
        },
        handleSubmit(e){
            if(this.$route.name=='OtpForm'){
                console.log(this.$route.name);
                this.submitAuth(e)
            }else if(this.$route.name == 'OtpForgetPass'){
                console.log(this.$route.name);
                this.submitForget(e)
            }
        },
        async submitAuth(e) {
                if (!this.disabled) {
                    this.otp.code=e;
                    this.state.loading = true
                    const res = await this.$axios.post('/users/check-otp-code', this.otp).catch(()=>{})
                    if (res.token) {
                        await this.$setToken(res.token)
                        await this.$router.push({name: 'Account'})
                    }
                }
        },
        async submitForget(e) {
                if (!this.disabled) {
                    this.otp.code=e;
                    this.state.loading = true
                    const res = await this.$axios.post('/users/v2/check-otp-password', this.otp)
                    if (res.token) {
                        this.state.otptoken=res.token
                        await this.$router.push({name: 'NewPass'})
                    }
                }
        },
        paste,
        reset,
    },
        beforeDestroy() {
            clearTimeout(this.timeOut)
        },
        mounted (){
            if (this.$route.params.authType !== 'GOOGLE_AUTHENTICATOR') {
                this.countDownTimer()
            }
        }
}
</script>


<style lang="scss" scoped>
.otp{
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    h3{
        font-family: 'sepahbod';
        font-size: clamp(20px,3vw,24px);
    }
    .ci-container{
        display: flex;
        flex-direction: column;
        row-gap: 32px;
        padding: 32px 24px;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        background: white;
        .title{
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 8px;
            a{
                text-align: end;
                color: var(--primary-color);
                font-size: clamp(12px,1vw,14px);
            }
        }
    }
    .counter{
        cursor: pointer;
        align-self: start;
    }
}
</style>