<template>
    <div class="navbar-container">
        <div class="items">
            <a @click.prevent="$router.push('/advancetrade')" href=""> معامله حرفه ای </a>
            <a @click.prevent="$router.push({name:'Faq'})" href=""> راهنمای استفاده </a>
            <a @click.prevent="$router.push({name:'Fee'})" href=""> کارمزد ها </a>
        </div>
        <div class="avatar">
            <div @click="$router.push({name:'Notifications'})" class="notif-container">
                <inline-svg :src="require('../../assets/icons/sidebarNotifs.svg')" alt="" class="notification" />
            </div>
            <div @click="dropToggle = !dropToggle" class="profile">
                <inline-svg class="arrowsvg" :src="require('../../assets/icons/inputarrow.svg')" />
                <img class="avatar-img" src="../../assets/icons/avatar.svg" alt="">
            </div>
            <transition name="drop">
            <div v-if="dropToggle" class="drop-down">
                <a @click.prevent="reD(item.address)" v-for="(item,index) in dropItems" :key="index" href="" class="item"> {{item.name}} </a>
                <a @click.prevent="$logout" href="" class="item"> خروج </a>
            </div>
            </transition>
        </div>
    </div>
</template>

<script>
    export default {
        name:'DashNavbar',
        data() {
            return {
                dropToggle:false,
                dropItems:[
                    {
                        name:'داشبورد',
                        address:'Dashboard',
                    },
                    {
                        name:'کیف پول',
                        address:'Wallet',
                    },
                    {
                        name:'حساب کاربری',
                        address:'Account',
                    },
                    {
                        name:'دعوت از دوستان',
                        address:'InviteFriends',
                    },
                ]
            }
        },
        methods: {
            reD(e){
                this.$router.push({name:e})
            }
        },
        created:function () {
            window.addEventListener("click", event => {
                if(!event.target.closest('.profile')){
                    this.dropToggle=false
                }
            })
        }
    }
</script>

<style lang="scss" scoped>
.notification{
    fill: var(--grey);
}
.items{
    display: flex;
    flex-direction: row;
    column-gap: 40px;
}
.navbar-container{
        width: 100%;
        display: flex;
        background: var(--home-background);
        z-index: 105;
        padding: 10px 20px;
        top: 0;
        flex-direction: row;
        justify-content: space-between;
        column-gap: 40px;
        align-items: center;
        a{
            color: var(--grey);
            font-weight: 400;
            font-size: clamp(11px,1vw,14px);
        }
}
.avatar{
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    column-gap: 40px;
    align-items: center;
    padding-left: 10px;
    .drop-down{
        overflow: hidden;
        position: absolute;
        top: 40px;
        left: 0;
        width: 100%;
        background: var(--home-background);
        border-radius: 12px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        .item{
            background: white;
            border-radius: 4px;
            padding: 5px;
            width: 100%;
            text-align: center;
        }
    }
    .profile{
        display: flex;
        flex-direction: row;
        column-gap: 5px;
        align-items: center;
    }
    .notif-container{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    background: var(--light-grey);
    border-radius: 4px;
    width: 36px;
    height: 36px;
}
}
@media only screen and(max-width:500px){
    .navbar-container{
        column-gap: 10px;
    }
.items{
    column-gap: 10px;
}
.avatar{
    column-gap: 10px;
    .avatar-img{
        display: none;
    }
}
}
</style>