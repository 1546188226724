<template>
    <div class="navbar">
        <div class="items">
            <div v-if="sideBar" class="nav-links-column">
                <span v-for="(item,index) in menuItems"  :key="index" class="link"> {{item.name}} </span>
            </div>
            <div class="logo">
                <div @click="openSideBar" class="hambtn">
                <span :class="{'cross1':sideBar}" class="lines"></span>
                <span :class="{'hidden':sideBar}"  class="lines"></span>
                <span :class="{'cross2':sideBar}"  class="lines"></span>
                </div>
                <img @click="$router.push('/')" src="../../assets/Logo.svg" alt="">
                <span @click="$router.push('/')"> cryptocurrency </span>
            </div>
            <div class="nav-links">
                <span @click="$router.push(item.addres)" v-for="(item,index) in menuItems"  :key="index" class="link"> {{item.name}} </span>
            </div>
        </div>
        <div class="btns">
            <button @click="$router.push('/login')" class="link"> ورود </button>
            <button @click="$router.push('/signup')" class="submit"> ثبت نام </button>
        </div>
    </div>
</template>
<script>
export default {
    name:'Navbar',
    data() {
        return {
            sideBar:false,
            menuItems:[
                {
                    name:'بازارها',
                    addres:'/market'
                },
                {
                    name:'راهنمای کاربران',
                    addres:'/faq'
                },
                {
                    name:'کارمزد ها',
                    addres:'/fee'
                },
                {
                    name:'بلاگ',
                    addres:'/'
                },
            ]
        }
    },
    methods:{
        openSideBar(){
            this.sideBar = !this.sideBar
            console.log(this.sideBar);
        }
    },
    mounted() {
        console.log('bitch')
    },
    created:function(){
        window.addEventListener("click", (event) => {
            if(!event.target.closest('.nav-links-column,.hambtn')){
                this.sideBar=false;
            }
        })
    }

}
</script>

<style lang="scss" scoped>
.hidden{
    display: none;
}
.cross1{
    transform: rotate(45deg);
}
.cross2{
    transform: rotate(-45deg) translate(5px,-6px);
}
.navbar{
    position: fixed;
    width: 100%;
    top: 0;
    background: white;
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 14px 12px;
    .btns{
        display: flex;
        align-items: center;
        flex-direction: row;
        column-gap: 8px;
        justify-content: flex-end;
        width: 20%;
        font-size: clamp(12px,1vw,14px);
    }
    .items{
        position: relative;
        display: flex;
        flex-direction: row;
        width: 60%;
        .logo{
            img{
                cursor: pointer;
            }
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-content: center;
            column-gap: 12px;
            .hambtn{
            z-index: 81;
            display: flex;
            flex-direction: column;
            row-gap: 5px;
            justify-content: center;
            height: 100%;
            width: 7%;
            .lines{
                transition: 300ms;
                border-radius: 10px;
                background: var(--primary-color);
                padding: 1.5px 12px;
                height: unset;
            }
            }
            span{
                align-self: center;
                height: 30px;
                font-size: clamp(14px,2vw,18px);
            }
        }
        .nav-links{
            width: 80%;
            display: flex;
            flex-direction: row;
            // justify-content: space-between;
            span{
                vertical-align: middle;
                font-weight: normal;
                height: 30px;
                max-width: 90px;
                font-size: clamp(12px,2vw,14px);
            }
        }
        .nav-links-column{
            z-index: 80;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            box-shadow: -6px 0 15px 10px rgba(202, 202, 202, 0.685);
            background: white;
            position: fixed;
            right: 0;
            top: 0;
            padding-top: 70px;
            row-gap: 20px;
            height: 100vh;
            width: 40%;
            display: flex;
            flex-direction: column;
            align-items: center;
            span{
                vertical-align: middle;
                font-weight: normal;
                height: 30px;
                max-width: 90px;
                font-size: clamp(12px,2vw,14px);
            }
        }
    }
}
button{
    max-height: 35px;
    max-width: 130px;
}
@media only screen and(max-width:850px){
    .items{
        width: 80% !important;
    }
}
@media only screen  and(min-width:600px){
    //     .nav-links-column{
    //     display: none !important;
    // }
    .hambtn{
        display: none !important;
    }
}
@media only screen and(max-width:600px){
    .nav-links{
        display: none !important;
    }
    .logo{
        width: 100%;
    }
    .btns{
        width: 40% !important;
    }
    .items{
        width: 80% !important;
    }
}
</style>