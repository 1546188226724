<template>
    <div class="register">
        <h2 class="title"> ورود </h2>
        <div class="btn-border">
            <button class="google">
                <img @click="$router.push({name:'Dashboard'})" class="googleicon" src="../../assets/icons/google.svg" alt="">
                <span> Login with Google  </span>
            </button>
        </div>
        <form @submit.prevent="submit">
            <div class="inputs">
                <div class="input-container">
                    <span class="input-span"> ایمیل </span>
                    <label class="no-just" for="email">
                        <img class="email-icon" src="../../assets/icons/darkEmail.svg" alt="">
                        <input v-model="data.email" type="text">
                    </label>
                </div>
                <div class="input-container">
                    <div class="spans">
                        <span class="input-span"> رمز عبور </span>
                        <a @click.prevent="$router.push('/forgetpass')" href="" class="forgoetpass">
                            <img src="../../assets/icons/warning.svg" alt="">
                            فراموشی رمز عبور
                            </a>
                    </div>
                    <label for="email">
                        <input v-model="data.password" :type="showpass ? 'text':'password'">
                        <img @click="showpass=true" v-if="!showpass" src="../../assets/icons/dontseepassword.svg" alt="">
                        <img @click="showpass=false" v-if="showpass" class="seepass" src="../../assets/icons/seepassword.svg" alt="">
                    </label>
                </div>
            </div>
            <div class="btns">
                <img src="../../assets/recaptcha.svg" alt="">
                <button  :disabled="disable" class="submit"> {{disable ? disable :'ورود'}} </button>
                <div class="gologin">
                    <span class="btn-text">
                     ثبت نام نکرده اید ؟
                    <a @click.prevent="$router.push('/signup')" href=""> ثبت نام کنبد </a>
                </span>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
const goto = {
        EMAIL: '/otpform/EMAIL',
        SMS: '/otpform/SMS',
        GOOGLE_AUTHENTICATOR: '/otpform/GOOGLE_AUTHENTICATOR',
}
export default {
    name:'Login',
    data(){
        return{
            showpass:false,
            checked:false,
            data:{
                    email: '',
                    password: '',
                    osType: '',
                    deviceType: '',
                    browser: '',
                    inviteCode:'',
            }
        }
    },
    computed:{
        disable(){
            let error
            if(!this.data.email){
                error='ایمیل را وارد کنید'
            }
            else
            if(!this.$validEmail(this.data.email)){
                error='ایمیل صحیح نیست'
            }
            else
            if(!this.data.password){
                error='پسوورد را وارد کنید'
            }
            else
            if(!this.$validPass(this.data.password) || this.data.password.length < 8){
                error='پسوورد باید حداقل ۸ کاراکتر باشد و شامل حروف کوچک و بزرگ باشد'
            }
            else{
                error= false
            }
            return error
        }
    },
    methods:{
        googleSso() {
                window.location.href = this.$google_sso
        },
        async submit(){
            this.state.loading = true
            this.data = {
                ...this.data,
                osType: this.$detectOs(),
                deviceType: this.$detectDevice(),
                browser: this.$detectBrowser() || 'CHROME'

            }
            const res = await this.$axios.post('/users/login', this.data)
            console.log(res);
            if (res.message === 'login successful, otp is required') {
                    
                    this.$cookies.set('username', this.data.email)
                    this.state.userInfo.email = this.data.email
                    this.state.userInfo.mobilenumber = res.baseDTO.starTwoStepAuthentication || '';

                    await this.$router.push(goto[res.baseDTO.towStepAuthenticationType])
                    // await this.$router.push('/Login-otp-email')

                }else if (res.token){
                    this.$setToken(res.token,this.state.userInfo.remember)
                    await this.$router.push({name: 'Validation'})
            }
            // this.$router.push('/dashboard')
        }
    },
    mounted() {
    },
}
</script>

<style lang="scss" scoped>

.btns{
    img{
        max-height: 64px;
    }
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    width: 100%;
    .btn-text{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;   
        text-align: center;
        a{
            color: var(--primary-color);
        }
    }
}
.pp{
    .checked{
        background: var(--primary-color) !important;
    }
    .checkbox{
        width: 20px;
        height: 20px;
        background: var(--home-background);
        border-radius: 4px;
        border: 1px solid var(--grey);
        cursor: pointer;
        &:hover{
            border: 1px solid var(--dark-primary);
            background: var(--light-yellow);
        }
    }
    a{
            color: var(--dark-primary);
    }
    display: flex;
    flex-direction: row;
    justify-content: start;
    column-gap: 12px;
    width: 100%;
    .pp-text{
        word-spacing: 4px;
    }
}
.inputs{
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}
.seepass{
    width: 20px !important;
    height: 20px !important;
}
.input-container{
    .email-icon{
            width: 14px !important;
            height: 20px !important; 
        }
    .input-span{
        margin-right: 30px;
        font-weight: 300;
    }
    .spans{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        img{
            width: 14px;
            height: 14px;
        }
        a{
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: 5px;
            color: var(--primary-color);
            font-size: clamp(12px,1vw,14px);
        }
    }
    img{
        width: 18px;
        height: 8px;
        cursor: pointer;
    }
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    label{
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        column-gap: 8px;
        align-items: center;
        width: 100%;
        background: var(--home-background);
        border-radius: 12px;
        padding: 12px;
        input{
            background: var(--home-background);
            width: 80%;
        }
    }
}
form{
    padding: 24px;
    background: white;
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    border-radius: 12px;
}
.btn-border{
    width: 100%;
    height: 100%;
    background: linear-gradient(90.2deg, rgba(251, 100, 100, 0.5) 0.09%, rgba(255, 184, 0, 0.5) 31.05%, rgba(255, 245, 0, 0.5) 57.02%, rgba(0, 255, 10, 0.5) 77.28%, rgba(0, 87, 255, 0.5) 99.83%);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.google{
    background: var(--light-yellow);
    color: var(--black-fee);
    padding: 8px;
    border-radius: 12px;
    transform: scaleY(.95);
    width: 99.5%;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    .googleicon{
        width: 24px;
        height: 24px;
    }
}
.register{
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    width: 100%;
}
.title{
    font-family: 'sepahbod';
    color: var(--purple-title);
}
</style>