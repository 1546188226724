<template>
    <div class="newpass">
        <div class="contetn-container">
            <h2> رمز عبور جدید </h2>
            <form @submit.prevent="send" class="newpass-container">
                <div class="input-container">
                    <div class="spans">
                        <span class="input-span"> رمز عبور جدید </span>
                    </div>
                    <label class="no-just" for="email">
                        <input v-model="data.password" :type="showpass ? 'text':'password'">
                        <img @click="showpass=true" v-if="!showpass" src="../../assets/icons/dontseepassword.svg" alt="">
                        <img @click="showpass=false" v-if="showpass" class="seepass" src="../../assets/icons/seepassword.svg" alt="">
                    </label>
                </div>
                <div class="input-container">
                    <div class="spans">
                        <span class="input-span"> تکرار رمز عبور </span>
                    </div>
                    <label for="email">
                        <input v-model="data.passRepeat" :type="showRepeat ? 'text':'password'">
                        <img @click="showRepeat=true" v-if="!showRepeat" src="../../assets/icons/dontseepassword.svg" alt="">
                        <img @click="showRepeat=false" v-if="showRepeat" class="seepass" src="../../assets/icons/seepassword.svg" alt="">
                    </label>
                </div>
                <button :disabled="disable" class="submit"> {{disable ? disable :'تایید'}} </button>
            </form>
        </div>
    </div>
</template>
<script>
export default {
    name:'NewPass',
    data() {
        return {
            showpass:false,
            showRepeat:false,
            data:{
                passRepeat:null,
                password:null
            },

        }
    },
    computed: {
        disable(){
            let err
                if(!this.data.password){
                    err = 'رمز جدید را وارد کنید'
                }else if( !this.$validPass(this.data.password)){
                    err = 'رمز باید شامل حروف بزرگ کوچک و عدد باشد'
                }else if( this.data.password.length < 8){
                    err = 'رمز باید حداقل شامل ۸ کاراکتر باشد'
                }
                else if(!this.data.passRepeat){
                    err = 'تکرار رمز را وارد کنید'
                }
                else if(this.data.passRepeat !== this.data.password){
                    err = 'رمز با تکرارش تشابه ندارد'
                }else{
                    err = false
                }
            return err
        }
    },
    methods:{
        send(){
            this.$route.push('/dashboard')
        },
        async submit() {
                if (!this.disable) {
                    this.state.loading = true
                    const headers = {"Authorization": `Bearer ${this.state.otptoken}`,}
                    let [res,] = await this.$http.post('/users/v2/reset-password',this.data,{headers},this.data,{headers})
                    console.log(res);
                    if (res) {
                        this.$error('رمز شما با موفقیت تغییر یافت', '', 'success')
                        await this.$router.push({name:'Login'})
                    }
                }
        },
    }
}
</script>

<style lang="scss" scoped>
.input-container{
    label{
        justify-content: space-around;
        img{
            cursor: pointer;
        }
    }
}
.newpass{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    justify-self: center;
    z-index: 5;
}
.contetn-container{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: 30%;
}
.newpass-container{
    row-gap: 32px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-radius: 12px;
    background: white;
}
@media only screen and(max-width:1300px){
    .contetn-container{
        width: 50%;
    }
}
@media only screen and(max-width:1100px){
    .contetn-container{
        width: 70%;
    }
}
@media only screen and(max-width:900px){
    .contetn-container{
        width: 85%;
    }
}
@media only screen and(max-width:600px){
    .contetn-container{
        width: 100%;
    }
}
</style>